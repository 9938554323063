import {
  FETCH_SUB_CATAGORYLIST_REQUEST,
  FETCH_SUB_CATAGORYLIST_SUCCESS,
  FETCH_SUB_CATAGORYLIST_FAILURE,
  UPDATE_SUB_CATAGORY_REQUEST,
  UPDATE_SUB_CATAGORY_SUCCESS,
  UPDATE_SUB_CATAGORY_FAILURE,
  // ADD_SUB_CATAGORY_REQUEST,
  // ADD_SUB_CATAGORY_SUCCESS,
  // ADD_SUB_CATAGORY_FAILURE,
  DELETE_SUB_CATAGORY_REQUEST,
  DELETE_SUB_CATAGORY_SUCCESS,
  DELETE_SUB_CATAGORY_FAILURE,
  GET_SUBCATEGORY_BY_CATEGORYID_REQUEST,
  GET_SUBCATEGORY_BY_CATEGORYID_SUCCESS,
  GET_SUBCATEGORY_BY_CATEGORYID_FAILURE
} from "../../type";
const initialState = {
  isLoading: false,
  data: [],
  error: "",
  message: "",
  subCategoryByCategory: []
};

const SubCatagoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUB_CATAGORYLIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_SUB_CATAGORYLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };
    case FETCH_SUB_CATAGORYLIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UPDATE_SUB_CATAGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_SUB_CATAGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
       
      };
    case UPDATE_SUB_CATAGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_SUBCATEGORY_BY_CATEGORYID_REQUEST:
      return {
        ...state,
        isLoading: true,
        subCategoryByCategory: []
      };

    case GET_SUBCATEGORY_BY_CATEGORYID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subCategoryByCategory: action.payload,
      };
    case GET_SUBCATEGORY_BY_CATEGORYID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };


      case DELETE_SUB_CATAGORY_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case DELETE_SUB_CATAGORY_SUCCESS:
        return {
          ...state,
          message: action.payload,
          isLoading: false,
        };
      case DELETE_SUB_CATAGORY_FAILURE:
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
    default:
      return state;
  }
};

export default SubCatagoryReducer;
