import {
  FETCH_BIDDING_LIST_REQUEST,
  FETCH_BIDDING_LIST_SUCCESS,
  FETCH_BIDDING_LIST_FAILURE,
  FETCH_PENDING_ORDERS_BIDDING_REQUEST,
  FETCH_PENDING_ORDERS_BIDDING_SUCCESS,
  FETCH_PENDING_ORDERS_BIDDING_FAILURE,
  FETCH_ORDER_DETAILS_BY_ID_REQUEST,
  FETCH_ORDER_DETAILS_BY_ID_SUCCESS,
  FETCH_ORDER_DETAILS_BY_ID_FAILURE,
  CREATE_BIDDING_REQUEST,
  CREATE_BIDDING_SUCCESS,
  CREATE_BIDDING_FAILURE,
  ACCEPT_BID_REQUEST,
  ACCEPT_BID_SUCCESS,
  ACCEPT_BID_FAILURE,
} from "../../type";

const initialState = {
  isLoading: false,
  data: [],
  error: "",
  message: "",
  pendingOrders: [],
  orderDetails: [],
  key:null
};

export const biddingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BIDDING_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_BIDDING_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };

    case FETCH_BIDDING_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_PENDING_ORDERS_BIDDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_PENDING_ORDERS_BIDDING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pendingOrders: action.payload,
      };

    case FETCH_PENDING_ORDERS_BIDDING_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_ORDER_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_ORDER_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderDetails: action.payload,
      };

    case FETCH_ORDER_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_BIDDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_BIDDING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        key: action.payload,
      };

    case CREATE_BIDDING_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case ACCEPT_BID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACCEPT_BID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
      };

    case ACCEPT_BID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
