import React from 'react'
import { Route, Redirect } from 'react-router-dom'
const PublicRoute = ({ LoadComponent, ...rest }) => {
    const userData = localStorage.getItem('userData')

    return (
        <Route {...rest}
            render={
                ({ location, props }) => userData ? (<
                    Redirect to={
                        {
                            pathname: "/dashboard"
                        }
                    }
                />
                ) : <LoadComponent {...props} />}
        />
    );
}
export default PublicRoute