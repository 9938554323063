import {
  ADD_BRAND_FAILURE,
  ADD_BRAND_REQUEST,
  ADD_BRAND_SUCCESS,
} from "../../type";

const initialState = {
  isLoading: true,
  message: "",
  catagoryRequestData: "",
};

const addBrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BRAND_REQUEST:
      return {
        ...state,
        isLoading: true,
        catagoryRequestData: action.payload,
      };
    case ADD_BRAND_SUCCESS:
      return {
        ...state,
        isLoading: false,

        catagoryRequestData: action.payload,
      };

    case ADD_BRAND_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};

export default addBrandReducer;
