import {
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
} from "src/store/type";

const initialState = {
  isLoading: true,
  data: [],
  error: "",
  message: "",
};

const ChangePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ChangePasswordReducer;
