import { combineReducers } from "redux";
import { subAdminReducer } from "./subAdmin/subAdminReducer";
import addSubAdminReducer from "./subAdmin/addSubAdminReducer";
import loginReducer from "./login/loginReducer";
import changeState from "./sidebar/sidebarReducer";
import userReducer from "./user/userReducer";
import revenueReducer from "./revenue/revenueReducer";
import orderReducer from "./order/orderReducer";
import BannerReducer from "./banner/bannerReducer";
import CMSReducer from "../reducers/cms/cmsReducer";
import CatagoryReducer from "./catagory/catagoryReducer";
import addCatagoryReducer from "./catagory/addCatagoryReducer";
import SubCatagoryReducer from "./subCatagory/subCatagoryReducer";
import addSubCatagoryReducer from "./subCatagory/addSubCatagoryReducer";
import PromocodeReducer from "./promocode/promocodeReducer";
import AddPromocodeReducer from "./promocode/addPromocodeReducer";
import { customerReducer } from "./customer/customerReducer";
import { vendorReducer } from "./vendor/vendorReducer";
import { notificationReducer } from "./notification/notificationReducer";
import productReducer from "./product/productReducer";
import inventoryReducer from "./inventory/inventoryReducer";
import sellerReducer from "./seller/sellerReducer";
import { paymentReducer } from "./payment/paymentReducer";
import { biddingReducer } from "./bidding/biddingReducer";
import { sendBiddingReducer } from "./bidding/sendBiddingReducer";
import { timeCompleteBiddingReducer } from "./bidding/timeCompleteBiddingReducer";
import { pastBiddingReducer } from "./bidding/pastBiddingReducer";
import BrandReducer from "./brand/brandReducer";
import addBrandReducer from "./brand/addBrandReducer";
import cartReducer from "./cart/cartReducer";
import AdminOrderReducer from "./order/adminOrderReducer";
import ProfileReducer from "./profile/profileReducer";
import ChangePasswordReducer from "./changePassword/changePasswordReducer";
import ForgetPasswordReducer from "./forgetPassword/forgetPasswordReducer";

const rootReducer = combineReducers({
  subAdmin: subAdminReducer,
  addSubAdmin: addSubAdminReducer,
  userData: loginReducer,
  changeState: changeState,
  totalUser: userReducer,
  totalRevenue: revenueReducer,
  totalOrders: orderReducer,
  banner: BannerReducer,
  cmsData: CMSReducer,
  catagory: CatagoryReducer,
  addCatagory: addCatagoryReducer,
  subCatagory: SubCatagoryReducer,
  addSubCatagory: addSubCatagoryReducer,
  promocode: PromocodeReducer,
  addPromocode: AddPromocodeReducer,
  customers: customerReducer,
  vendor: vendorReducer,
  notification: notificationReducer,
  products: productReducer,
  inventory: inventoryReducer,
  seller: sellerReducer,
  payment: paymentReducer,
  bidding: biddingReducer,
  sendBidding: sendBiddingReducer,
  timeCompleteBidding: timeCompleteBiddingReducer,
  pastBidding : pastBiddingReducer,
  brand:BrandReducer,
  addBrand: addBrandReducer,
  cart:cartReducer,
  adminOrder : AdminOrderReducer,
  profile:ProfileReducer,
  changePassword: ChangePasswordReducer,
  forgetPassword:ForgetPasswordReducer
});
export default rootReducer;
