import {
    FETCH_BannerList_REQUEST, FETCH_BannerList_SUCCESS, FETCH_BannerList_FAILURE,
    UPDATE_Banner_REQUEST, UPDATE_Banner_SUCCESS, UPDATE_Banner_FAILURE,
    ADD_Banner_REQUEST, ADD_Banner_SUCCESS, ADD_Banner_FAILURE, DELETE_BANNER_REQUEST, DELETE_BANNER_SUCCESS, DELETE_BANNER_FAILURE

} from '../../type'
const initialState = {
    isLoading: false,
    data: [],
    error: '',
    message: ''

}



const BannerReducer = (state = initialState, action) => {

    switch (action.type) {

        case FETCH_BannerList_REQUEST:
            return {
                ...state,
                isLoading: true,


            }

        case FETCH_BannerList_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                message: action.payload.message

            }
        case FETCH_BannerList_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }



        case UPDATE_Banner_REQUEST:
            return {
                ...state,
                isLoading: true,


            }

        case UPDATE_Banner_SUCCESS:
            return {
                ...state,
                isLoading: false,
               

            }
        case UPDATE_Banner_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case ADD_Banner_REQUEST:
            return {
                ...state,
                isLoading: true,


            }

        case ADD_Banner_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                message: action.payload.message

            }
        case ADD_Banner_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }

            case DELETE_BANNER_REQUEST:
                return {
                  ...state,
                  isLoading: true,
                };
              case DELETE_BANNER_SUCCESS:
                return {
                  ...state,
                  message: action.payload,
                  isLoading: false,
                };
              case DELETE_BANNER_FAILURE:
                return {
                  ...state,
                  error: action.payload,
                  isLoading: false,
                };





        default:
            return state
    }

}

export default BannerReducer