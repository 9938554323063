const initialState = {
    isLoading: true,
    message: '',
    subAdminRequestData: ''
}

const ADD_SubAdmin_REQUEST = 'ADD_SubAdmin_REQUEST';
const ADD_SubAdmin_SUCCESS = 'ADD_SubAdmin_SUCCESS';
const ADD_SubAdmin_FAILURE = 'ADD_SubAdmin_FAILURE';


const addSubAdminReducer = (state = initialState, action) => {


    switch (action.type) {
        case ADD_SubAdmin_REQUEST:
            return {
                ...state,
                isLoading: true,
                subAdminRequestData: action.payload


            }
        case ADD_SubAdmin_SUCCESS:
            return {
                ...state,
                isLoading: false,

                subAdminRequestData: action.payload
            }

        case ADD_SubAdmin_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            }

        default:
            return state
    }

}

export default addSubAdminReducer