import {
    FETCH_PAST_BIDDING_LIST_REQUEST,
    FETCH_PAST_BIDDING_LIST_SUCCESS,
    FETCH_PAST_BIDDING_LIST_FAILURE,
  } from "../../type";
  
  const initialState = {
    isLoading: true,
    data: [],
    error: "",
    message: "",
  };
  
  export const pastBiddingReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PAST_BIDDING_LIST_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case FETCH_PAST_BIDDING_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
          message: action.payload.message,
        };
  
      case FETCH_PAST_BIDDING_LIST_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  