import {
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAILURE,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILURE,
} from "../../type";

const initialState = {
  isLoading: true,
  data: [],
  error: "",
  message: "",
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };

    case FETCH_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SEND_NOTIFICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case SEND_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
