import {
  FETCH_PROMOCODELIST_REQUEST,
  FETCH_PROMOCODELIST_SUCCESS,
  FETCH_PROMOCODELIST_FAILURE,
  UPDATE_PROMOCODE_REQUEST,
  UPDATE_PROMOCODE_SUCCESS,
  UPDATE_PROMOCODE_FAILURE,
  // ADD_PROMOCODE_REQUEST,
  // ADD_PROMOCODE_SUCCESS,
  // ADD_PROMOCODE_FAILURE,
  GET_PROMOCODE_DATA_REQUEST,
  GET_PROMOCODE_DATA_SUCCESS,
  GET_PROMOCODE_DATA_FAILURE,
  REMOVE_PROMOCODE_REQUEST,
  REMOVE_PROMOCODE_SUCCESS,
  REMOVE_PROMOCODE_FAILURE,
} from "../../type";

const initialState = {
  isLoading: true,
  data: [],
  error: "",
  message: "",
};

const PromocodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROMOCODELIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_PROMOCODELIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };
    case FETCH_PROMOCODELIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UPDATE_PROMOCODE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_PROMOCODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };
    case UPDATE_PROMOCODE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_PROMOCODE_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: [],
      };
    case GET_PROMOCODE_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_PROMOCODE_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

      case REMOVE_PROMOCODE_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case REMOVE_PROMOCODE_SUCCESS:
        return {
          ...state,
          message: action.payload,
          isLoading: false,
        };
      case REMOVE_PROMOCODE_FAILURE:
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };

    default:
      return state;
  }
};

export default PromocodeReducer;
