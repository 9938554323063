import {
  TOTAL_ORDERS_REQUEST,
  TOTAL_ORDERS_SUCCESS,
  TOTAL_ORDERS_FAILURE,
  FETCH_ONGOING_ORDER_LIST_REQUEST,
  FETCH_ONGOING_ORDER_LIST_SUCCESS,
  FETCH_ONGOING_ORDER_LIST_FAILURE,
  FETCH_PAST_ORDER_LIST_REQUEST,
  FETCH_PAST_ORDER_LIST_SUCCESS,
  FETCH_PAST_ORDER_LIST_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
} from "../../type";
const initialState = {
  isLoading: true,
  data: [],
  onGoingOrders: [],
  pastOrders: [],
  error: "",
  message: "",
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOTAL_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case TOTAL_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case TOTAL_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case FETCH_ONGOING_ORDER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_ONGOING_ORDER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        onGoingOrders: action.payload,
      };

    case FETCH_ONGOING_ORDER_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case FETCH_PAST_ORDER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_PAST_ORDER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pastOrders: action.payload,
      };

    case FETCH_PAST_ORDER_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case PLACE_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case PLACE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
      };

    case PLACE_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default orderReducer;
