import { ADD_CATAGORY_FAILURE, ADD_CATAGORY_REQUEST, ADD_CATAGORY_SUCCESS } from "../../type"

const initialState = {
    isLoading: true,
    message: '',
    catagoryRequestData: ''
}




const addCatagoryReducer = (state = initialState, action) => {


    switch (action.type) {
        case ADD_CATAGORY_REQUEST:
            return {
                ...state,
                isLoading: true,
               


            }
        case ADD_CATAGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,

                catagoryRequestData: action.payload
            }

        case ADD_CATAGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            }

        default:
            return state
    }

}

export default addCatagoryReducer