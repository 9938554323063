import {
  ADD_CUSTOMER_FOR_ORDER_FAILURE,
  ADD_CUSTOMER_FOR_ORDER_REQUEST,
  ADD_CUSTOMER_FOR_ORDER_SUCCESS,
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  FETCH_CART_FAILURE,
  FETCH_CART_REQUEST,
  FETCH_CART_SUCCESS,
  GET_CART_DETAILS_FAILURE,
  GET_CART_DETAILS_REQUEST,
  GET_CART_DETAILS_SUCCESS,
  UPDATE_CART_FAILURE,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
} from "../../type";
const initialState = {
  isLoading: true,
  cart: [],
  customer: [],
  error: "",
  message: "",
  data:[]
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CART_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_CART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        //   message: action.payload.message,
      };
    case FETCH_CART_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UPDATE_CART_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // cart: action.payload,
        message: action.payload,
      };
    case UPDATE_CART_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ADD_TO_CART_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cart: action.payload,
        //   message: action.payload.message
      };
    case ADD_TO_CART_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ADD_CUSTOMER_FOR_ORDER_REQUEST:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_CUSTOMER_FOR_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customer: action.payload,
        // message: action.payload.message
      };

    case ADD_CUSTOMER_FOR_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_CART_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CART_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cartDetails: action.payload,
      };

    case GET_CART_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default cartReducer;
