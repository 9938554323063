import {
  TOTAL_REVENUE_REQUEST,
  TOTAL_REVENUE_SUCCESS,
  TOTAL_REVENUE_FAILURE,
} from "../../type";
const initialState = {
  isLoading: true,
  data: [],
  error: "",
  message: "",
};


const revenueReducer = (state = initialState, action) => {


  switch (action.type) {
    case TOTAL_REVENUE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case TOTAL_REVENUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };

    case TOTAL_REVENUE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default revenueReducer;


