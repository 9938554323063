import {
    FETCH_SubAdmin_AccessModulesList_REQUEST, FETCH_SubAdmin_AccessModulesList_SUCCESS, FETCH_SubAdmin_AccessModulesList_FAILURE, UPDATE_SubAdmin_AccessModule_REQUEST,
    UPDATE_SubAdmin_AccessModule_SUCCESS,
    UPDATE_SubAdmin_AccessModule_FAILURE,
    UPDATE_SubAdmin_REQUEST,
    UPDATE_SubAdmin_SUCCESS,
    UPDATE_SubAdmin_FAILURE,
} from '../../type'
const initialState = {
    isLoading: true,
    data: [],
    error: '',
    message: ''

}
const FETCH_SubAdmin_REQUEST = 'FETCH_SubAdmin_REQUEST';
const FETCH_SubAdmin_SUCCESS = 'FETCH_SubAdmin_SUCCESS';
const FETCH_SubAdmin_FAILURE = 'FETCH_SubAdmin_FAILURE';
// const ADD_SubAdmin_REQUEST = 'ADD_SubAdmin_REQUEST';
// const ADD_SubAdmin_SUCCESS = 'ADD_SubAdmin_SUCCESS';
// const ADD_SubAdmin_FAILURE = 'ADD_SubAdmin_FAILURE';
// const DELETE_SubAdmin_REQUEST = 'DELETE_SubAdmin_REQUEST';
// const DELETE_SubAdmin_SUCCESS = 'DELETE_SubAdmin_SUCCESS';
// const DELETE_SubAdmin_FAILURE = 'DELETE_SubAdmin_FAILURE';


export const subAdminReducer = (state = initialState, action) => {


    switch (action.type) {

        case FETCH_SubAdmin_REQUEST:
            return {
                ...state,
                isLoading: true,


            }

        case FETCH_SubAdmin_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.users,
                message: action.payload.message

            }

        case FETCH_SubAdmin_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,


            }

        case FETCH_SubAdmin_AccessModulesList_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case FETCH_SubAdmin_AccessModulesList_SUCCESS:
            return {
                ...state,
                isLoading: false,
                accessModuleList: action.payload
            }
        case FETCH_SubAdmin_AccessModulesList_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }

        case UPDATE_SubAdmin_AccessModule_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case UPDATE_SubAdmin_AccessModule_SUCCESS:
            return {
                ...state,
                message: action.payload,
                isLoading: false

            }


        case UPDATE_SubAdmin_AccessModule_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload

            }

        case UPDATE_SubAdmin_REQUEST:
            return {
                ...state,
                isLoading: true

            }

        case UPDATE_SubAdmin_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload
            }
        case UPDATE_SubAdmin_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }




        default:
            return state
    }

}


// const addSubadminInitialState = {
//     isLoading: true,
//     message: ''
// }



// export const addSubAdminReducer = (state = addSubadminInitialState, action) => {


//     switch (action.type) {
//         case ADD_SubAdmin_REQUEST:
//             return {
//                 ...state,
//                 isLoading: true


//             }
//         case ADD_SubAdmin_SUCCESS:
//             return {
//                 ...state,
//                 isLoading: false,
//                 message: action.payload.message
//             }

//         case ADD_SubAdmin_FAILURE:
//             return {
//                 ...state,
//                 isLoading: false,
//                 message: action.payload.message
//             }

//         default:
//             return state
//     }

// }