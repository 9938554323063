const initialState = {
    isLoading: false,
    userData: [],
    message: '',
    error: '',
    isAuthenticated: false
}
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const LOGOUT_USER = 'LOGOUT_USER';
const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                userData: action.payload,
                isAuthenticated: true,
                isLoading: false,
                error: '',



            }
        case LOGIN_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            }
        case LOGOUT_USER:
            return {
                ...state,
                isAuthenticated: false,
                userData: [],
                isLoading: false,
                error: '',
            }
        default:
            return state
    }
}

export default loginReducer