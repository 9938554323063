import {
  ADMIN_ORDERS_FAILURE,
  ADMIN_ORDERS_REQUEST,
  ADMIN_ORDERS_SUCCESS,
  FETCH_ADMIN_ONGOING_ORDER_LIST_FAILURE,
  FETCH_ADMIN_ONGOING_ORDER_LIST_REQUEST,
  FETCH_ADMIN_ONGOING_ORDER_LIST_SUCCESS,
  FETCH_ADMIN_PAST_ORDER_LIST_FAILURE,
  FETCH_ADMIN_PAST_ORDER_LIST_REQUEST,
  FETCH_ADMIN_PAST_ORDER_LIST_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  TOTAL_ORDERS_FAILURE,
  TOTAL_ORDERS_REQUEST,
  TOTAL_ORDERS_SUCCESS,
} from "src/store/type";

const initialState = {
  isLoading: true,
  data: [],
  onGoingOrders: [],
  pastOrders: [],
  error: "",
  message: "",
  orderInfo:{}
};

const AdminOrderReducer = (state = initialState, action) => {
  switch (action.type) {

    case TOTAL_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case TOTAL_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case TOTAL_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ADMIN_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ADMIN_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case ADMIN_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      case GET_ORDER_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case GET_ORDER_SUCCESS:
        return {
          ...state,
          isLoading: false,
          orderInfo: action.payload,
        };
  
      case GET_ORDER_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };

    case FETCH_ADMIN_ONGOING_ORDER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_ADMIN_ONGOING_ORDER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        onGoingOrders: action.payload,
      };

    case FETCH_ADMIN_ONGOING_ORDER_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case FETCH_ADMIN_PAST_ORDER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_ADMIN_PAST_ORDER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pastOrders: action.payload,
      };

    case FETCH_ADMIN_PAST_ORDER_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default AdminOrderReducer;
