import {
  FETCH_PAYMENT_REQUEST,
  FETCH_PAYMENT_SUCCESS,
  FETCH_PAYMENT_FAILURE,
  FETCH_FILTERED_PAYMENT_REQUEST,
  FETCH_FILTERED_PAYMENT_SUCCESS,
  FETCH_FILTERED_PAYMENT_FAILURE
} from "../../type";

const initialState = {
  isLoading: true,
  data: [],
  error: "",
  message: "",
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };

    case FETCH_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_FILTERED_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_FILTERED_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };

    case FETCH_FILTERED_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
