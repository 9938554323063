import {
  TOTAL_PRODUCTS_COUNT_REQUEST,
  TOTAL_PRODUCTS_COUNT_SUCCESS,
  TOTAL_PRODUCTS_COUNT_FAILURE,
  FETCH_PRODUCT_LIST_REQUEST,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_LIST_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_DETAILS_REQUEST,
  UPDATE_PRODUCT_DETAILS_SUCCESS,
  UPDATE_PRODUCT_DETAILS_FAILURE,
  CHANGE_PRODUCT_TYPE_REQUEST,
  CHANGE_PRODUCT_TYPE_SUCCESS,
  CHANGE_PRODUCT_TYPE_FAILURE,
  GET_PRODUCT_DATA_REQUEST,
  GET_PRODUCT_DATA_SUCCESS,
  GET_PRODUCT_DATA_FAILURE,
  UPLOAD_PRODUCT_IMAGE_REQUEST,
  UPLOAD_PRODUCT_IMAGE_SUCCESS,
  UPLOAD_PRODUCT_IMAGE_FAILURE,
} from "../../type";
const initialState = {
  isLoading: false,
  products: [],
  addedProductId: "",
  message: "",
  error: "",
  isAuthenticated: false,
  productInfo: {},
};
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOTAL_PRODUCTS_COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TOTAL_PRODUCTS_COUNT_SUCCESS:
      return {
        ...state,
        products: action.payload,
        isLoading: false,
      };
    case TOTAL_PRODUCTS_COUNT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        products: [],
        isLoading: true,
      };
    case FETCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        products: action.payload.message === "none" ? [] : action.payload,
        isLoading: false,
      };
    case FETCH_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case ADD_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addedProductId: action.payload,
      };
    case ADD_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isLoading: false,
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case UPDATE_PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isLoading: false,
      };
    case UPDATE_PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case CHANGE_PRODUCT_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isLoading: false,
      };
    case CHANGE_PRODUCT_TYPE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case GET_PRODUCT_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        productInfo: {},
      };
    case GET_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        productInfo: action.payload,
        isLoading: false,
      };
    case GET_PRODUCT_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case UPLOAD_PRODUCT_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        productInfo: [],
      };
    case UPLOAD_PRODUCT_IMAGE_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isLoading: false,
      };
    case UPLOAD_PRODUCT_IMAGE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default productReducer;
