import {
    FETCH_INVENTORY_LIST_REQUEST,
    FETCH_INVENTORY_LIST_SUCCESS,
    FETCH_INVENTORY_LIST_FAILURE,
    CHANGE_PRODUCT_STOCK_STATUS_REQUEST,
    CHANGE_PRODUCT_STOCK_STATUS_SUCCESS,
    CHANGE_PRODUCT_STOCK_STATUS_FAILURE
} from '../../type'


const initialState = {
    isLoading: true,
    products: [],
    message: '',
    error: '',
}
const inventoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INVENTORY_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case FETCH_INVENTORY_LIST_SUCCESS:
            return {
                ...state,
                products: action.payload,
                isLoading: false


            }
        case FETCH_INVENTORY_LIST_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            }
        case CHANGE_PRODUCT_STOCK_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case CHANGE_PRODUCT_STOCK_STATUS_SUCCESS:
            return {
                ...state,
                products: action.payload,
                isLoading: false


            }
        case CHANGE_PRODUCT_STOCK_STATUS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            }
        default:
            return state

    }

}

export default inventoryReducer