import {
    FETCH_SELLER_LIST_REQUEST,
    FETCH_SELLER_LIST_SUCCESS,
    FETCH_SELLER_LIST_FAILURE,
    FETCH_SELLER_DETAILS_REQUEST,
    FETCH_SELLER_DETAILS_SUCCESS,
    FETCH_SELLER_DETAILS_FAILURE
}
    from '../../type'

const initialState = {
    isLoading: true,
    products: [],
    message: '',
    error: '',
    isAuthenticated: false,
}
const sellerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SELLER_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case FETCH_SELLER_LIST_SUCCESS:
            return {
                ...state,
                details: action.payload,
                isLoading: false


            }
        case FETCH_SELLER_LIST_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            }



        case FETCH_SELLER_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case FETCH_SELLER_DETAILS_SUCCESS:
            return {
                ...state,
                products: action.payload,
                isLoading: false


            }
        case FETCH_SELLER_DETAILS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            }
        default:
            return state

    }

}

export default sellerReducer