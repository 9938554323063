import {
  FETCH_VENDOR_LIST_REQUEST,
  FETCH_VENDOR_LIST_SUCCESS,
  FETCH_VENDOR_LIST_FAILURE,
  UPDATE_VENDOR_DETAILS_REQUEST,
  UPDATE_VENDOR_DETAILS_SUCCESS,
  UPDATE_VENDOR_DETAILS_FAILURE,
  DELETE_VENDOR_REQUEST,
  DELETE_VENDOR_SUCCESS,
  DELETE_VENDOR_FAILURE,
  UPDATE_VENDOR_STATUS_REQUEST,
  UPDATE_VENDOR_STATUS_SUCCESS,
  UPDATE_VENDOR_STATUS_FAILURE,
  VENDOR_MY_LIST_PRODUCTS_REQUEST,
  VENDOR_MY_LIST_PRODUCTS_SUCCESS,
  VENDOR_MY_LIST_PRODUCTS_FAILURE,
  REGISTER_VENDOR_REQUEST,
  REGISTER_VENDOR_SUCCESS,
  REGISTER_VENDOR_FAILURE,
} from "../../type";

const initialState = {
  isLoading: true,
  data: [],
  error: "",
  message: "",
  products: [],
  vendor: {},
};

export const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VENDOR_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_VENDOR_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        // message: action.payload.message,
      };

    case FETCH_VENDOR_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

  

    // VENDOR_MY_LIST_PRODUCTS_REQUEST
    case VENDOR_MY_LIST_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case VENDOR_MY_LIST_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: action.payload,
        // message: action.payload.message,
      };

    case VENDOR_MY_LIST_PRODUCTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UPDATE_VENDOR_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_VENDOR_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case UPDATE_VENDOR_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_VENDOR_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_VENDOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case DELETE_VENDOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UPDATE_VENDOR_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_VENDOR_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: "success",
        message: action.payload.message,
      };

    case UPDATE_VENDOR_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case REGISTER_VENDOR_REQUEST:
      return {
        ...state,
        userData: [],
        isLoading: true,
      };

    case REGISTER_VENDOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
        // isAuthenticated: true
      };

    case REGISTER_VENDOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
