import { FETCH_PROFILE_FAILURE, FETCH_PROFILE_REQUEST, FETCH_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS } from "src/store/type";

  const initialState = {
    isLoading: true,
    data: [],
    error: "",
    message: "",
  };
  
  const ProfileReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PROFILE_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case FETCH_PROFILE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
          message: action.payload.message,
        };
      case FETCH_PROFILE_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
  
      case UPDATE_PROFILE_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case UPDATE_PROFILE_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };
      case UPDATE_PROFILE_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
     
  
      default:
        return state;
    }
  };
  
  export default ProfileReducer;
  