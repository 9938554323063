import {
  FETCH_TIME_COMPLETE_BIDDING_REQUEST,
  FETCH_TIME_COMPLETE_BIDDING_SUCCESS,
  FETCH_TIME_COMPLETE_BIDDING_FAILURE,
  FETCH_BID_DETAILS_BY_ID_REQUEST,
  FETCH_BID_DETAILS_BY_ID_SUCCESS,
  FETCH_BID_DETAILS_BY_ID_FAILURE,
} from "../../type";

const initialState = {
  isLoading: true,
  data: [],
  error: "",
  message: "",
  bidDetails: {},
};

export const timeCompleteBiddingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TIME_COMPLETE_BIDDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_TIME_COMPLETE_BIDDING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };

    case FETCH_TIME_COMPLETE_BIDDING_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case FETCH_BID_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_BID_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bidDetails: action.payload,
      };

    case FETCH_BID_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
