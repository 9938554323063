import { ADD_SUB_CATAGORY_FAILURE, ADD_SUB_CATAGORY_REQUEST, ADD_SUB_CATAGORY_SUCCESS } from "../../type"

const initialState = {
    isLoading: true,
    message: '',
    subCatagoryRequestData: ''
}




const addSubCatagoryReducer = (state = initialState, action) => {


    switch (action.type) {
        case ADD_SUB_CATAGORY_REQUEST:
            return {
                ...state,
                isLoading: true,
                subCatagoryRequestData: action.payload


            }
        case ADD_SUB_CATAGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,

                subCatagoryRequestData: action.payload
            }

        case ADD_SUB_CATAGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            }

        default:
            return state
    }

}

export default addSubCatagoryReducer