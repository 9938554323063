import {
  ADD_CMS_REQUEST,
  ADD_CMS_SUCCESS,
  ADD_CMS_FAILURE,
  UPDATE_CMS_REQUEST,
  UPDATE_CMS_SUCCESS,
  UPDATE_CMS_FAILURE,
  FETCH_CMS_REQUEST,
  FETCH_CMS_SUCCESS,
  FETCH_CMS_FAILURE,
  FETCH_CMS_VIEW_REQUEST,
  FETCH_CMS_VIEW_SUCCESS,
  FETCH_CMS_VIEW_FAILURE,
} from "../../type";

const initialState = {
  isLoading: true,
  data: [],
  error: "",
  message: "",
  html:""
};

const CMSReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CMS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_CMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };
    case FETCH_CMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_CMS_VIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_CMS_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        html: action.payload,
        message: action.payload.message,
      };
    case FETCH_CMS_VIEW_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_CMS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_CMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };
    case UPDATE_CMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ADD_CMS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ADD_CMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };
    case ADD_CMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default CMSReducer;
