import {
  FETCH_BRAND_LIST_REQUEST,
  FETCH_BRAND_LIST_SUCCESS,
  FETCH_BRAND_LIST_FAILURE,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAILURE,
  DELETE_BRAND_REQUEST,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAILURE,
  UPLOAD_BRAND_IMAGE_REQUEST,
  UPLOAD_BRAND_IMAGE_SUCCESS,
  UPLOAD_BRAND_IMAGE_FAILURE,
  GET_BRAND_REQUEST,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAILURE,
} from "../../type";
const initialState = {
  isLoading: true,
  data: [],
  error: "",
  message: "",
  image: "",
  brand: {},
};

const BrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BRAND_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_BRAND_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };
    case FETCH_BRAND_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_BRAND_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_BRAND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        brand: action.payload,
        message: action.payload,
      };
    case GET_BRAND_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UPDATE_BRAND_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_BRAND_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_BRAND_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isLoading: false,
      };
    case DELETE_BRAND_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case UPLOAD_BRAND_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPLOAD_BRAND_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        image: action.payload,
        message: action.payload.message,
      };
    case UPLOAD_BRAND_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default BrandReducer;
