import {
  TOTAL_USERS_COUNT_REQUEST,
  TOTAL_USERS_COUNT_SUCCESS,
  TOTAL_USERS_COUNT_FAILURE,
} from "../../type";
const initialState = {
  isLoading: true,
  data: [],
  error: "",
  message: "",
};
// const TOTAL_USERS_COUNT_REQUEST = 'TOTAL_USERS_COUNT_REQUEST';
// const TOTAL_USERS_COUNT_SUCCESS = 'TOTAL_USERS_COUNT_SUCCESS';
// const TOTAL_USERS_COUNT_FAILURE = 'TOTAL_USERS_COUNT_FAILURE';
// const ADD_SubAdmin_REQUEST = 'ADD_SubAdmin_REQUEST';
// const ADD_SubAdmin_SUCCESS = 'ADD_SubAdmin_SUCCESS';
// const ADD_SubAdmin_FAILURE = 'ADD_SubAdmin_FAILURE';
// const DELETE_SubAdmin_REQUEST = 'DELETE_SubAdmin_REQUEST';
// const DELETE_SubAdmin_SUCCESS = 'DELETE_SubAdmin_SUCCESS';
// const DELETE_SubAdmin_FAILURE = 'DELETE_SubAdmin_FAILURE';

const userReducer = (state = initialState, action) => {


  switch (action.type) {
    case TOTAL_USERS_COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case TOTAL_USERS_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };

    case TOTAL_USERS_COUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default userReducer;

// const addSubadminInitialState = {
//     isLoading: true,
//     message: ''
// }

// export const addSubAdminReducer = (state = addSubadminInitialState, action) => {

//     switch (action.type) {
//         case ADD_SubAdmin_REQUEST:
//             return {
//                 ...state,
//                 isLoading: true

//             }
//         case ADD_SubAdmin_SUCCESS:
//             return {
//                 ...state,
//                 isLoading: false,
//                 message: action.payload.message
//             }

//         case ADD_SubAdmin_FAILURE:
//             return {
//                 ...state,
//                 isLoading: false,
//                 message: action.payload.message
//             }

//         default:
//             return state
//     }

// }
