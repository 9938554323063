import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers/rootReducer'
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';

// const initialState = {
//     sidebarShow: 'responsive',
//     asideShow: false,
//     darkMode: false
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//     switch (type) {
//         case 'set':
//             return {...state, ...rest }
//         default:
//             return state
//     }
// }

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(ReduxThunk)));
export default store