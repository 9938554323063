import {
  FETCH_CUSTOMER_LIST_REQUEST,
  FETCH_CUSTOMER_LIST_SUCCESS,
  FETCH_CUSTOMER_LIST_FAILURE,
  UPDATE_CUSTOMER_DETAILS_REQUEST,
  UPDATE_CUSTOMER_DETAILS_SUCCESS,
  UPDATE_CUSTOMER_DETAILS_FAILURE,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_STATUS_REQUEST,
  UPDATE_CUSTOMER_STATUS_SUCCESS,
  UPDATE_CUSTOMER_STATUS_FAILURE,
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
} from "../../type";

const initialState = {
  isLoading: true,
  data: [],
  error: "",
  message: "",
  user: {},
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        // message: action.payload.message
      };

    case FETCH_CUSTOMER_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_CUSTOMER_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case UPDATE_CUSTOMER_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UPDATE_CUSTOMER_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_CUSTOMER_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: "success",
        message: action.payload.message,
      };

    case UPDATE_CUSTOMER_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ADD_CUSTOMER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case GET_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        // message: action.payload.message,
      };

    case GET_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
