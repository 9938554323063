import {
  FETCH_CATAGORYLIST_REQUEST,
  FETCH_CATAGORYLIST_SUCCESS,
  FETCH_CATAGORYLIST_FAILURE,
  UPDATE_CATAGORY_REQUEST,
  UPDATE_CATAGORY_SUCCESS,
  UPDATE_CATAGORY_FAILURE,
  DELETE_CATAGORY_REQUEST,
  DELETE_CATAGORY_SUCCESS,
  DELETE_CATAGORY_FAILURE,
} from "../../type";
const initialState = {
  isLoading: false,
  data: [],
  error: "",
  message: "",
};

const CatagoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATAGORYLIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_CATAGORYLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        message: action.payload.message,
      };
    case FETCH_CATAGORYLIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UPDATE_CATAGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_CATAGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
       
      };
    case UPDATE_CATAGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

      case DELETE_CATAGORY_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case DELETE_CATAGORY_SUCCESS:
        return {
          ...state,
          message: action.payload,
          isLoading: false,
        };
      case DELETE_CATAGORY_FAILURE:
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
  
    // case ADD_CATAGORY_REQUEST:
    //     return {
    //         ...state,
    //         isLoading: true,

    //     }

    // case ADD_CATAGORY_SUCCESS:
    //     return {
    //         ...state,
    //         isLoading: false,
    //         data: action.payload,
    //         message: action.payload.message

    //     }
    // case ADD_CATAGORY_FAILURE:
    //     return {
    //         ...state,
    //         isLoading: false,
    //         error: action.payload
    //     }

    default:
      return state;
  }
};

export default CatagoryReducer;
