// User login and userdetails
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";

// Dashboard
// total number of users of the platform
//total orders, total revenue and total completed orders

export const TOTAL_PRODUCTS_COUNT_REQUEST = "TOTAL_PRODUCTS_COUNT_REQUEST";
export const TOTAL_PRODUCTS_COUNT_SUCCESS = "TOTAL_PRODUCTS_COUNT_SUCCESS";
export const TOTAL_PRODUCTS_COUNT_FAILURE = "TOTAL_PRODUCTS_COUNT_FAILURE";

export const TOTAL_USERS_COUNT_REQUEST = "TOTAL_USERS_COUNT_REQUEST";
export const TOTAL_USERS_COUNT_SUCCESS = "TOTAL_USERS_COUNT_SUCCESS";
export const TOTAL_USERS_COUNT_FAILURE = "TOTAL_USERS_COUNT_FAILURE";

export const TOTAL_ORDERS_REQUEST = "TOTAL_ORDERS_REQUEST";
export const TOTAL_ORDERS_SUCCESS = "TOTAL_ORDERS_SUCCESS";
export const TOTAL_ORDERS_FAILURE = "TOTAL_ORDERS_FAILURE";

export const ADMIN_ORDERS_REQUEST = "ADMIN_ORDERS_REQUEST";
export const ADMIN_ORDERS_SUCCESS = "ADMIN_ORDERS_SUCCESS";
export const ADMIN_ORDERS_FAILURE = "ADMIN_ORDERS_FAILURE";

export const TOTAL_REVENUE_REQUEST = "TOTAL_REVENUE_REQUEST";
export const TOTAL_REVENUE_SUCCESS = "TOTAL_REVENUE_SUCCESS";
export const TOTAL_REVENUE_FAILURE = "TOTAL_REVENUE_FAILURE";

export const FETCH_SubAdmin_REQUEST = "FETCH_SubAdmin_REQUEST";
export const FETCH_SubAdmin_SUCCESS = "FETCH_SubAdmin_SUCCESS";
export const FETCH_SubAdmin_FAILURE = "FETCH_SubAdmin_FAILURE";
export const DELETE_SubAdmin_REQUEST = "DELETE_SubAdmin_REQUEST";
export const DELETE_SubAdmin_SUCCESS = "DELETE_SubAdmin_SUCCESS";
export const DELETE_SubAdmin_FAILURE = "DELETE_SubAdmin_FAILURE";
export const ADD_SubAdmin_REQUEST = "ADD_SubAdmin_REQUEST";
export const ADD_SubAdmin_SUCCESS = "ADD_SubAdmin_SUCCESS";
export const ADD_SubAdmin_FAILURE = "ADD_SubAdmin_FAILURE";

export const FETCH_SubAdmin_AccessModulesList_REQUEST =
  "FETCH_SubAdmin_AccessModulesList_REQUEST";
export const FETCH_SubAdmin_AccessModulesList_SUCCESS =
  "FETCH_SubAdmin_AccessModulesList_SUCCESS";
export const FETCH_SubAdmin_AccessModulesList_FAILURE =
  "FETCH_SubAdmin_AccessModulesList_FAILURE";

export const UPDATE_SubAdmin_AccessModule_REQUEST =
  "UPDATE_SubAdmin_AccessModule_REQUEST";
export const UPDATE_SubAdmin_AccessModule_SUCCESS =
  "UPDATE_SubAdmin_AccessModule_SUCCESS";
export const UPDATE_SubAdmin_AccessModule_FAILURE =
  "UPDATE_SubAdmin_AccessModule_FAILURE";

export const UPDATE_SubAdmin_REQUEST = "UPDATE_SubAdmin_REQUEST";
export const UPDATE_SubAdmin_SUCCESS = "UPDATE_SubAdmin_SUCCESS";
export const UPDATE_SubAdmin_FAILURE = "UPDATE_SubAdmin_FAILURE";

export const FETCH_BannerList_REQUEST = "FETCH_BannerList_REQUEST";
export const FETCH_BannerList_SUCCESS = "FETCH_BannerList_SUCCESS";
export const FETCH_BannerList_FAILURE = "FETCH_BannerList_FAILURE";

export const UPDATE_Banner_REQUEST = "UPDATE_Banner_REQUEST";
export const UPDATE_Banner_SUCCESS = "UPDATE_Banner_SUCCESS";
export const UPDATE_Banner_FAILURE = "UPDATE_Banner_FAILURE";

export const ADD_Banner_REQUEST = "ADD_Banner_REQUEST";
export const ADD_Banner_SUCCESS = "ADD_Banner_SUCCESS";
export const ADD_Banner_FAILURE = "ADD_Banner_FAILURE";

export const FETCH_CMS_REQUEST = "FETCH_CMS_REQUEST";
export const FETCH_CMS_SUCCESS = "FETCH_CMS_SUCCESS";
export const FETCH_CMS_FAILURE = "FETCH_CMS_FAILURE";

export const FETCH_CMS_VIEW_REQUEST = "FETCH_CMS_VIEW_REQUEST";
export const FETCH_CMS_VIEW_SUCCESS = "FETCH_CMS_VIEW_SUCCESS";
export const FETCH_CMS_VIEW_FAILURE = "FETCH_CMS_VIEW_FAILURE";

export const ADD_CMS_REQUEST = "ADD_CMS_REQUEST";
export const ADD_CMS_SUCCESS = "ADD_CMS_SUCCESS";
export const ADD_CMS_FAILURE = "ADD_CMS_FAILURE";

export const UPDATE_CMS_REQUEST = "UPDATE_CMS_REQUEST";
export const UPDATE_CMS_SUCCESS = "UPDATE_CMS_SUCCESS";
export const UPDATE_CMS_FAILURE = "UPDATE_CMS_FAILURE";

export const FETCH_CATAGORYLIST_REQUEST = "FETCH_CATAGORYLIST_REQUEST";
export const FETCH_CATAGORYLIST_SUCCESS = "FETCH_CATAGORYLIST_SUCCESS";
export const FETCH_CATAGORYLIST_FAILURE = "FETCH_CATAGORYLIST_FAILURE";

export const UPDATE_CATAGORY_REQUEST = "UPDATE_CATAGORY_REQUEST";
export const UPDATE_CATAGORY_SUCCESS = "UPDATE_CATAGORY_SUCCESS";
export const UPDATE_CATAGORY_FAILURE = "UPDATE_CATAGORY_FAILURE";

export const ADD_CATAGORY_REQUEST = "ADD_CATAGORY_REQUEST";
export const ADD_CATAGORY_SUCCESS = "ADD_CATAGORY_SUCCESS";
export const ADD_CATAGORY_FAILURE = "ADD_CATAGORY_FAILURE";

export const FETCH_SUB_CATAGORYLIST_REQUEST = "FETCH_SUB_CATAGORYLIST_REQUEST";
export const FETCH_SUB_CATAGORYLIST_SUCCESS = "FETCH_SUB_CATAGORYLIST_SUCCESS";
export const FETCH_SUB_CATAGORYLIST_FAILURE = "FETCH_SUB_CATAGORYLIST_FAILURE";

export const UPDATE_SUB_CATAGORY_REQUEST = "UPDATE_SUB_CATAGORY_REQUEST";
export const UPDATE_SUB_CATAGORY_SUCCESS = "UPDATE_SUB_CATAGORY_SUCCESS";
export const UPDATE_SUB_CATAGORY_FAILURE = "UPDATE_SUB_CATAGORY_FAILURE";

export const ADD_SUB_CATAGORY_REQUEST = "ADD_SUB_CATAGORY_REQUEST";
export const ADD_SUB_CATAGORY_SUCCESS = "ADD_SUB_CATAGORY_SUCCESS";
export const ADD_SUB_CATAGORY_FAILURE = "ADD_SUB_CATAGORY_FAILURE";

export const ADD_SUB_SUB_CATAGORY_REQUEST = "ADD_SUB_SUB_CATAGORY_REQUEST";
export const ADD_SUB_SUB_CATAGORY_SUCCESS = "ADD_SUB_SUB_CATAGORY_SUCCESS";
export const ADD_SUB_SUB_CATAGORY_FAILURE = "ADD_SUB_SUB_CATAGORY_FAILURE";

export const FETCH_SUB_SUB_CATAGORYLIST_REQUEST = "FETCH_SUB_SUB_CATAGORYLIST_REQUEST";
export const FETCH_SUB_SUB_CATAGORYLIST_SUCCESS = "FETCH_SUB_SUB_CATAGORYLIST_SUCCESS";
export const FETCH_SUB_SUB_CATAGORYLIST_FAILURE = "FETCH_SUB_SUB_CATAGORYLIST_FAILURE";

export const FETCH_CUSTOMER_LIST_REQUEST = "FETCH_CUSTOMER_LIST_REQUEST";
export const FETCH_CUSTOMER_LIST_SUCCESS = "FETCH_CUSTOMER_LIST_SUCCESS";
export const FETCH_CUSTOMER_LIST_FAILURE = "FETCH_CUSTOMER_LIST_FAILURE";

export const UPDATE_CUSTOMER_DETAILS_REQUEST =
  "UPDATE_CUSTOMER_DETAILS_REQUEST";
export const UPDATE_CUSTOMER_DETAILS_SUCCESS =
  "UPDATE_CUSTOMER_DETAILS_SUCCESS";
export const UPDATE_CUSTOMER_DETAILS_FAILURE =
  "UPDATE_CUSTOMER_DETAILS_FAILURE";

export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER_REQUEST";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILURE = "DELETE_CUSTOMER_FAILURE";

export const ADD_CUSTOMER_REQUEST = "ADD_CUSTOMER_REQUEST";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";

export const UPDATE_CUSTOMER_STATUS_REQUEST = "UPDATE_CUSTOMER_STATUS_REQUEST";
export const UPDATE_CUSTOMER_STATUS_SUCCESS = "UPDATE_CUSTOMER_STATUS_SUCCESS";
export const UPDATE_CUSTOMER_STATUS_FAILURE = "UPDATE_CUSTOMER_STATUS_FAILURE";

export const FETCH_PROMOCODELIST_REQUEST = "FETCH_PROMOCODELIST_REQUEST";
export const FETCH_PROMOCODELIST_SUCCESS = "FETCH_PROMOCODELIST_SUCCESS";
export const FETCH_PROMOCODELIST_FAILURE = "FETCH_PROMOCODELIST_FAILURE";

export const UPDATE_PROMOCODE_REQUEST = "UPDATE_PROMOCODE_REQUEST";
export const UPDATE_PROMOCODE_SUCCESS = "UPDATE_PROMOCODE_SUCCESS";
export const UPDATE_PROMOCODE_FAILURE = "UPDATE_PROMOCODE_FAILURE";

export const REMOVE_PROMOCODE_REQUEST = "REMOVE_PROMOCODE_REQUEST";
export const REMOVE_PROMOCODE_SUCCESS = "REMOVE_PROMOCODE_SUCCESS";
export const REMOVE_PROMOCODE_FAILURE = "REMOVE_PROMOCODE_FAILURE";

export const ADD_PROMOCODE_REQUEST = "ADD_PROMOCODE_REQUEST";
export const ADD_PROMOCODE_SUCCESS = "ADD_PROMOCODE_SUCCESS";
export const ADD_PROMOCODE_FAILURE = "ADD_PROMOCODE_FAILURE";

export const GET_PROMOCODE_DATA_REQUEST = "GET_PROMOCODE_DATA_REQUEST";
export const GET_PROMOCODE_DATA_SUCCESS = "GET_PROMOCODE_DATA_SUCCESS";
export const GET_PROMOCODE_DATA_FAILURE = "GET_PROMOCODE_DATA_FAILURE";

export const FETCH_VENDOR_LIST_REQUEST = "FETCH_VENDOR_LIST_REQUEST";
export const FETCH_VENDOR_LIST_SUCCESS = "FETCH_VENDOR_LIST_SUCCESS";
export const FETCH_VENDOR_LIST_FAILURE = "FETCH_VENDOR_LIST_FAILURE";

export const UPDATE_VENDOR_DETAILS_REQUEST = "UPDATE_VENDOR_DETAILS_REQUEST";
export const UPDATE_VENDOR_DETAILS_SUCCESS = "UPDATE_VENDOR_DETAILS_SUCCESS";
export const UPDATE_VENDOR_DETAILS_FAILURE = "UPDATE_VENDOR_DETAILS_FAILURE";

export const DELETE_VENDOR_REQUEST = "DELETE_VENDOR_REQUEST";
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS";
export const DELETE_VENDOR_FAILURE = "DELETE_VENDOR_FAILURE";

export const UPDATE_VENDOR_STATUS_REQUEST = "UPDATE_VENDOR_STATUS_REQUEST";
export const UPDATE_VENDOR_STATUS_SUCCESS = "UPDATE_VENDOR_STATUS_SUCCESS";
export const UPDATE_VENDOR_STATUS_FAILURE = "UPDATE_VENDOR_STATUS_FAILURE";

export const ADD_VENDOR_REQUEST = "ADD_VENDOR_REQUEST";
export const ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS";
export const ADD_VENDOR_FAILURE = "ADD_VENDOR_FAILURE";

export const FETCH_NOTIFICATION_REQUEST = "FETCH_NOTIFICATION_REQUEST";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAILURE = "FETCH_NOTIFICATION_FAILURE";

export const FETCH_PAYMENT_REQUEST = "FETCH_PAYMENT_REQUEST";
export const FETCH_PAYMENT_SUCCESS = "FETCH_PAYMENT_SUCCESS";
export const FETCH_PAYMENT_FAILURE = "FETCH_PAYMENT_FAILURE";

export const SEND_NOTIFICATION_REQUEST = "SEND_NOTIFICATION_REQUEST";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILURE = "SEND_NOTIFICATION_FAILURE";

export const FETCH_ONGOING_ORDER_LIST_REQUEST =
  "FETCH_ONGOING_ORDER_LIST_REQUEST";
export const FETCH_ONGOING_ORDER_LIST_SUCCESS =
  "FETCH_ONGOING_ORDER_LIST_SUCCESS";
export const FETCH_ONGOING_ORDER_LIST_FAILURE =
  "FETCH_ONGOING_ORDER_LIST_FAILURE";

export const FETCH_PAST_ORDER_LIST_REQUEST = "FETCH_PAST_ORDER_LIST_REQUEST";
export const FETCH_PAST_ORDER_LIST_SUCCESS = "FETCH_PAST_ORDER_LIST_SUCCESS";
export const FETCH_PAST_ORDER_LIST_FAILURE = "FETCH_PAST_ORDER_LIST_FAILURE";

export const UPDATE_ORDER_DETAILS_REQUEST = "UPDATE_ORDER_DETAILS_REQUEST";
export const UPDATE_ORDER_DETAILS_SUCCESS = "UPDATE_ORDER_DETAILS_SUCCESS";
export const UPDATE_ORDER_DETAILS_FAILURE = "UPDATE_ORDER_DETAILS_FAILURE";

export const FETCH_PRODUCT_LIST_REQUEST = "FETCH_PRODUCT_LIST_REQUEST";
export const FETCH_PRODUCT_LIST_SUCCESS = "FETCH_PRODUCT_LIST_SUCCESS";
export const FETCH_PRODUCT_LIST_FAILURE = "FETCH_PRODUCT_LIST_FAILURE";

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAIURE";

export const UPDATE_PRODUCT_DETAILS_REQUEST = "UPDATE_PRODUCT_DETAILS_REQUEST";
export const UPDATE_PRODUCT_DETAILS_SUCCESS = "UPDATE_PRODUCT_DETAILS_SUCCESS";
export const UPDATE_PRODUCT_DETAILS_FAILURE = "UPDATE_PRODUCT_DETAILS_FAILURE";

export const CHANGE_PRODUCT_TYPE_REQUEST = "CHANGE_PRODUCT_TYPE_REQUEST";
export const CHANGE_PRODUCT_TYPE_SUCCESS = "CHANGE_PRODUCT_TYPE_SUCCESS";
export const CHANGE_PRODUCT_TYPE_FAILURE = "CHANGE_PRODUCT_TYPE_FAILURE";

export const FETCH_INVENTORY_LIST_REQUEST = "FETCH_INVENTORY_LIST_REQUEST";
export const FETCH_INVENTORY_LIST_SUCCESS = "FETCH_INVENTORY_LIST_SUCCESS";
export const FETCH_INVENTORY_LIST_FAILURE = "FETCH_INVENTORY_LIST_FAILURE";

export const CHANGE_PRODUCT_STOCK_STATUS_REQUEST =
  "CHANGE_PRODUCT_STOCK_STATUS_REQUEST";
export const CHANGE_PRODUCT_STOCK_STATUS_SUCCESS =
  "CHANGE_PRODUCT_STOCK_STATUS_SUCCESS";
export const CHANGE_PRODUCT_STOCK_STATUS_FAILURE =
  "CHANGE_PRODUCT_STOCK_STATUS_FAILURE";

export const FETCH_SELLER_LIST_REQUEST = "FETCH_SELLER_LIST_REQUEST";
export const FETCH_SELLER_LIST_SUCCESS = "FETCH_SELLER_LIST_SUCCESS";
export const FETCH_SELLER_LIST_FAILURE = "FETCH_SELLER_LIST_FAILURE";

export const FETCH_SELLER_DETAILS_REQUEST = "FETCH_SELLER_DETAILS_REQUEST";
export const FETCH_SELLER_DETAILS_SUCCESS = "FETCH_SELLER_DETAILS_SUCCESS";
export const FETCH_SELLER_DETAILS_FAILURE = "FETCH_SELLER_DETAILS_FAILURE";

export const FETCH_PAST_BIDDING_LIST_REQUEST =
  "FETCH_PAST_BIDDING_LIST_REQUEST";
export const FETCH_PAST_BIDDING_LIST_SUCCESS =
  "FETCH_PAST_BIDDING_LIST_SUCCESS";
export const FETCH_PAST_BIDDING_LIST_FAILURE =
  "FETCH_PAST_BIDDING_LIST_FAILURE";

export const FETCH_BIDDING_LIST_REQUEST = "FETCH_BIDDING_LIST_REQUEST";
export const FETCH_BIDDING_LIST_SUCCESS = "FETCH_BIDDING_LIST_SUCCESS";
export const FETCH_BIDDING_LIST_FAILURE = "FETCH_BIDDING_LIST_FAILURE";

export const FETCH_SEND_BIDDING_REQUEST = "FETCH_SEND_BIDDING_REQUEST";
export const FETCH_SEND_BIDDING_SUCCESS = "FETCH_SEND_BIDDING_SUCCESS";
export const FETCH_SEND_BIDDING_FAILURE = "FETCH_SEND_BIDDING_FAILURE";

export const FETCH_MANAGE_BIDDING_REQUEST = "FETCH_MANAGE_BIDDING_REQUEST";
export const FETCH_MANAGE_BIDDING_SUCCESS = "FETCH_MANAGE_BIDDING_SUCCESS";
export const FETCH_MANAGE_BIDDING_FAILURE = "FETCH_MANAGE_BIDDING_FAILURE";

export const FETCH_TIME_COMPLETE_BIDDING_REQUEST =
  "FETCH_TIME_COMPLETE_BIDDING_REQUEST";
export const FETCH_TIME_COMPLETE_BIDDING_SUCCESS =
  "FETCH_TIME_COMPLETE_BIDDING_SUCCESS";
export const FETCH_TIME_COMPLETE_BIDDING_FAILURE =
  "FETCH_TIME_COMPLETE_BIDDING_FAILURE";

export const FETCH_FINAL_BIDDING_REQUEST = "FETCH_FINAL_BIDDING_REQUEST";
export const FETCH_FINAL_BIDDING_SUCCESS = "FETCH_FINAL_BIDDING_SUCCESS";
export const FETCH_FINAL_BIDDING_FAILURE = "FETCH_FINAL_BIDDING_FAILURE";

export const FETCH_BRAND_LIST_REQUEST = "FETCH_BRAND_LIST_REQUEST";
export const FETCH_BRAND_LIST_SUCCESS = "FETCH_BRAND_LIST_SUCCESS";
export const FETCH_BRAND_LIST_FAILURE = "FETCH_BRAND_LIST_FAILURE";

export const UPDATE_BRAND_REQUEST = "UPDATE_BRAND_REQUEST";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_FAILURE = "UPDATE_BRAND_FAILURE";

export const ADD_BRAND_REQUEST = "ADD_BRAND_REQUEST";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS";
export const ADD_BRAND_FAILURE = "ADD_BRAND_FAILURE";

export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";

export const FETCH_CART_REQUEST = "FETCH_CART_REQUEST";
export const FETCH_CART_SUCCESS = "FETCH_CART_SUCCESS";
export const FETCH_CART_FAILURE = "FETCH_CART_FAILURE";

export const UPDATE_CART_REQUEST = "UPDATE_CART_REQUEST";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_FAILURE = "UPDATE_CART_FAILURE";

export const REMOVE_FROM_CART_REQUEST = "REMOVE_FROM_CART_REQUEST";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_FAILURE = "REMOVE_FROM_CART_FAILURE";

export const ADD_CUSTOMER_FOR_ORDER_REQUEST = "ADD_CUSTOMER_FOR_ORDER_REQUEST";
export const ADD_CUSTOMER_FOR_ORDER_SUCCESS = "ADD_CUSTOMER_FOR_ORDER_SUCCESS";
export const ADD_CUSTOMER_FOR_ORDER_FAILURE = "ADD_CUSTOMER_FOR_ORDER_FAILURE";

export const FETCH_ADMIN_ONGOING_ORDER_LIST_REQUEST =
  "FETCH_ADMIN_ONGOING_ORDER_LIST_REQUEST";
export const FETCH_ADMIN_ONGOING_ORDER_LIST_SUCCESS =
  "FETCH_ADMIN_ONGOING_ORDER_LIST_SUCCESS";
export const FETCH_ADMIN_ONGOING_ORDER_LIST_FAILURE =
  "FETCH_ADMIN_ONGOING_ORDER_LIST_FAILURE";

export const FETCH_ADMIN_PAST_ORDER_LIST_REQUEST =
  "FETCH_ADMIN_PAST_ORDER_LIST_REQUEST";
export const FETCH_ADMIN_PAST_ORDER_LIST_SUCCESS =
  "FETCH_ADMIN_PAST_ORDER_LIST_SUCCESS";
export const FETCH_ADMIN_PAST_ORDER_LIST_FAILURE =
  "FETCH_ADMIN_PAST_ORDER_LIST_FAILURE";

export const FETCH_FILTERED_PAYMENT_REQUEST = "FETCH_FILTERED_PAYMENT_REQUEST";
export const FETCH_FILTERED_PAYMENT_SUCCESS = "FETCH_FILTERED_PAYMENT_SUCCESS";
export const FETCH_FILTERED_PAYMENT_FAILURE = "FETCH_FILTERED_PAYMENT_FAILURE";

export const GET_PRODUCT_DATA_REQUEST = "GET_PRODUCT_DATA_REQUEST";
export const GET_PRODUCT_DATA_SUCCESS = "GET_PRODUCT_DATA_SUCCESS";
export const GET_PRODUCT_DATA_FAILURE = "GET_PRODUCT_DATA_FAILURE";

export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILURE = "DELETE_BRAND_FAIURE";

export const DELETE_CATAGORY_REQUEST = "DELETE_CATAGORY_REQUEST";
export const DELETE_CATAGORY_SUCCESS = "DELETE_CATAGORY_SUCCESS";
export const DELETE_CATAGORY_FAILURE = "DELETE_CATAGORY_FAIURE";

export const DELETE_SUB_CATAGORY_REQUEST = "DELETE_SUB_CATAGORY_REQUEST";
export const DELETE_SUB_CATAGORY_SUCCESS = "DELETE_SUB_CATAGORY_SUCCESS";
export const DELETE_SUB_CATAGORY_FAILURE = "DELETE_SUB_CATAGORY_FAIURE";
export const GET_SUBCATEGORY_BY_CATEGORYID_REQUEST =
  "GET_SUBCATEGORY_BY_CATEGORYID_REQUEST";
export const GET_SUBCATEGORY_BY_CATEGORYID_SUCCESS =
  "GET_SUBCATEGORY_BY_CATEGORYID_SUCCESS";
export const GET_SUBCATEGORY_BY_CATEGORYID_FAILURE =
  "GET_SUBCATEGORY_BY_CATEGORYID_FAILURE";

export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_FAILURE = "DELETE_BANNER_FAIURE";
export const UPLOAD_PRODUCT_IMAGE_REQUEST = "UPLOAD_PRODUCT_IMAGE_REQUEST";
export const UPLOAD_PRODUCT_IMAGE_SUCCESS = "UPLOAD_PRODUCT_IMAGE_SUCCESS";
export const UPLOAD_PRODUCT_IMAGE_FAILURE = "UPLOAD_PRODUCT_IMAGE_FAILURE";

export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const EXCEL_PRODUCT_UPLOAD_REQUEST = "EXCEL_PRODUCT_UPLOAD_REQUEST";
export const EXCEL_PRODUCT_UPLOAD_SUCCESS = "EXCEL_PRODUCT_UPLOAD_SUCCESS";
export const EXCEL_PRODUCT_UPLOAD_FAILURE = "EXCEL_PRODUCT_UPLOAD_FAILURE";

export const GET_VENDOR_LIST_REQUEST = "GET_VENDOR_LIST_REQUEST";
export const GET_VENDOR_LIST_SUCCESS = "GET_VENDOR_LIST_SUCCESS";
export const GET_VENDOR_LIST_FAILURE = "GET_VENDOR_LIST_FAILURE";

export const VENDOR_MY_LIST_PRODUCTS_REQUEST =
  "VENDOR_MY_LIST_PRODUCTS_REQUEST";
export const VENDOR_MY_LIST_PRODUCTS_SUCCESS =
  "VENDOR_MY_LIST_PRODUCTS_SUCCESS";
export const VENDOR_MY_LIST_PRODUCTS_FAILURE =
  "VENDOR_MY_LIST_PRODUCTS_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";

export const REGISTER_VENDOR_REQUEST = "REGISTER_VENDOR_REQUEST";
export const REGISTER_VENDOR_SUCCESS = "REGISTER_VENDOR_SUCCESS";
export const REGISTER_VENDOR_FAILURE = "REGISTER_VENDOR_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";

export const GET_CART_DETAILS_REQUEST = "GET_CART_DETAILS_REQUEST";
export const GET_CART_DETAILS_SUCCESS = "GET_CART_DETAILS_SUCCESS";
export const GET_CART_DETAILS_FAILURE = "GET_CART_DETAILS_FAILURE";

export const FETCH_PENDING_ORDERS_BIDDING_REQUEST = "FETCH_PENDING_ORDERS_BIDDING_REQUEST";
export const FETCH_PENDING_ORDERS_BIDDING_SUCCESS = "FETCH_PENDING_ORDERS_BIDDING_SUCCESS";
export const FETCH_PENDING_ORDERS_BIDDING_FAILURE = "FETCH_PENDING_ORDERS_BIDDING_FAILURE";

export const FETCH_ORDER_DETAILS_BY_ID_REQUEST = "FETCH_ORDER_DETAILS_BY_ID_REQUEST";
export const FETCH_ORDER_DETAILS_BY_ID_SUCCESS = "FETCH_ORDER_DETAILS_BY_ID_SUCCESS";
export const FETCH_ORDER_DETAILS_BY_ID_FAILURE = "FETCH_ORDER_DETAILS_BY_ID_FAILURE";
export const UPLOAD_BRAND_IMAGE_REQUEST = "UPLOAD_BRAND_IMAGE_REQUEST";
export const UPLOAD_BRAND_IMAGE_SUCCESS = "UPLOAD_BRAND_IMAGE_SUCCESS";
export const UPLOAD_BRAND_IMAGE_FAILURE = "UPLOAD_BRAND_IMAGE_FAILURE";
export const GET_BRAND_REQUEST = "GET_BRAND_REQUEST";
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS";
export const GET_BRAND_FAILURE = "GET_BRAND_FAILURE";

export const PLACE_ORDER_REQUEST = "PLACE_ORDER_REQUEST";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAILURE = "PLACE_ORDER_FAILURE";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";
export const CREATE_BIDDING_REQUEST = "CREATE_BIDDING_REQUEST";
export const CREATE_BIDDING_SUCCESS = "CREATE_BIDDING_SUCCESS";
export const CREATE_BIDDING_FAILURE = "CREATE_BIDDING_FAILURE";


export const FETCH_BID_DETAILS_BY_ID_REQUEST = "FETCH_BID_DETAILS_BY_ID_REQUEST";
export const FETCH_BID_DETAILS_BY_ID_SUCCESS = "FETCH_BID_DETAILS_BY_ID_SUCCESS";
export const FETCH_BID_DETAILS_BY_ID_FAILURE = "FETCH_BID_DETAILS_BY_ID_FAILURE";

export const ACCEPT_BID_REQUEST = "ACCEPT_BID_REQUEST"
export const ACCEPT_BID_SUCCESS = "ACCEPT_BID_SUCCESS"
export const ACCEPT_BID_FAILURE = "ACCEPT_BID_FAILURE"


