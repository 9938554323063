import {
  ADD_PROMOCODE_FAILURE,
  ADD_PROMOCODE_REQUEST,
  ADD_PROMOCODE_SUCCESS,
} from "../../type";

const initialState = {
  isLoading: true,
  message: "",
  promocodeRequestData: "",
};

const AddPromocodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PROMOCODE_REQUEST:
      return {
        ...state,
        isLoading: true,
        promocodeRequestData: action.payload,
      };
    case ADD_PROMOCODE_SUCCESS:
      return {
        ...state,
        isLoading: false,

        promocodeRequestData: action.payload,
      };

    case ADD_PROMOCODE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};

export default AddPromocodeReducer;
